<template>
    <div class="main">
        <div class="bg-wrp">
            <div class="color-face"></div>
        </div>
        <div class="login-wrp">
            <!-- <img class="logo-png" src="../assets/logo0.png" alt="" srcset="" /> -->
            <div class="login-inner">
                <h3>后台登录</h3>
                <div class="user input-wrp">
                    <span>
                        <img src="../assets/user.png" alt="" srcset="" />
                    </span>
                    <el-input type="text" placeholder="用户名或账号" v-model="name"></el-input>
                </div>
                <div class="passwd input-wrp">
                    <span>
                        <img src="../assets/password.png" alt="" srcset="" />
                    </span>
                    <el-input type="password" placeholder="密码" v-model="password" show-password clear
                        @keydown.enter="active_login"></el-input>
                </div>
                <div class="radio-wrp admswrp">
                    <el-radio v-model="userType" :label="0" @change="change_type">管理员/旅行社</el-radio>
                    <el-radio v-model="userType" :label="1" @change="change_type">分支管理员</el-radio>
                </div>
                <el-button type="primary" @click="active_login">登录</el-button>
            </div>

            <!-- <div class="login-inner">
                <p>
                    请至新网址登录：<a href="https://www.wzsosu.net/web" target="_blank">https://www.wzsosu.net/web</a>
                </p>
            </div> -->



        </div>
        <div class="copyrit">
            <!-- <a href="https://beian.miit.gov.cn/"> 浙ICP备15026163号-1</a> &nbsp;&nbsp;&nbsp;
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33038302330479"><img style="width:12px" src="../assets/gongan.png"> 浙公网安备 33038302330479号</a> -->
            <div>版权所有 &copy; 浙江微在线网络科技有限公司 <br/> <a href="https://beian.miit.gov.cn">浙ICP备15026163号-1</a> </div>
        </div>

        <!-- <div class="imprt-tit">
            <p>网站系统将于2023年11月13日7时-11时转至新网站 <a href="https://www.wzsosu.net/web" target="_blank">https://www.wzsosu.net/web</a>，请即时保存新网址！</p>
            <p>后续将不再维护此网站，请及时更换为新网站！</p>
        </div> -->

    </div>
</template>

<script>
import router from '../router';
export default {
    data() {
        return {
            name: "",
            password: "",
            userType: 0,
        };
    },
    beforeCreate() {
        // console.log(this.$route)
        document.title = this.$route.meta.title
    },

    methods: {
        change_type() {
            console.log("userType:", this.userType)
        },
        active_login() {
            sessionStorage.setItem("userType", this.userType);
            let type = (this.userType == 0 ? 'login' : 'arealogin')
            this.ipost(
                "/AdminTravel/login.ashx?type=" + type, {
                name: this.name,
                password: this.password,
            },
                (res) => {
                    console.log("res:", res);
                    sessionStorage.setItem("loginState", true);
                    let checkType = (this.userType == 0 ? 'checklogin' : 'checkarealogin')
                    this.ipost("/AdminTravel/login.ashx?type=" + checkType, {}, (checkRes) => {
                        console.log("checkRes:", checkRes)
                        sessionStorage.setItem("userInfo", JSON.stringify(checkRes.model));
                        // 0：管理员，1：旅行社
                        if (checkRes.model.TravelAgency_Authority == 1) {
                            // 旅行社管理员
                            this.$router.push({
                                path: "/agency/policySells"
                            });
                        } else if (checkRes.model.TravelAgency_Authority == 0) {
                            // 系统管理员
                            this.$router.push({
                                path: "/home/travelAgencyList"
                            });
                        } else if (checkRes.model.TravelAgency_Authority == 2) {
                            // 财险特殊
                            this.$router.push({
                                path: "/insurance/count"
                            });
                        }
                        else {
                            // 分支公司
                            this.$router.push({
                                path: "/area/travelList"
                            });
                        }
                    })
                }
            );
        },
    },
};
</script>

<style scoped>
.imprt-tit {
    position: fixed;
    top: 8px;
    left: 8px;
    width: 320px;
    border-radius: 8px;
    padding: 12px;
    margin: 12px;
    font-size: 13px;
    line-height: 1.7;
    text-align: justify;
    box-shadow: 0 0 5px tomato;
    background-color: rgba(255, 255, 255, 0.4);
    color: #666;
    font-weight: bold;
    border: 2px solid tomato;

}
.imprt-tit a{
    color: tomato;
    font-size: 17px;
}

.copyrit {
    font-size: 12px;
    color: #ddd;
    text-align: center;
    position: absolute;
    bottom: 6px;
    width: 100%;
    left: 0;
}

.copyrit a {
    color: #ddd;
}

.osys {
    display: flex;
    justify-content: center;
    align-items: center;
}

.osys a {
    display: inline-block;
    margin: 16px 6px;
    font-size: 13px;
    color: #999;
}

.main {
    min-width: 980px;
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: #fdfdfd;
}

.bg-wrp {
    position: relative;
    width: 100%;
    height: 60%;
    background-image: url("../assets/laptop_blue2.jpg");
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
}

.color-face {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
}

.login-wrp {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
}

.logo-png {
    display: block;
    width: 31%;
    margin: 20px auto;
}

.login-inner {
    width: 47%;
    min-width: 330px;
    margin: 0 auto;
    padding: 10px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 12px #ccc;
}

.login-inner h3 {
    text-align: center;
    color: #00a1e9;
}

.input-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px auto;
}

.input-wrp span {
    padding: 0 12px;
    border-right: 1px solid #eee;
}

.input-wrp img {
    width: 16px;
}

.input-wrp input {
    box-sizing: border-box;
    padding-left: 10px;
    line-height: 31px;
    width: 90%;
}

.input-wrp input:focus {
    outline-color: #00a1e9;
}

.el-input input {
    border: none;
}

.el-button {
    margin: 12px 1.5%;
    width: 97%;
}

.admswrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px;
}

.copyright {
    color: #999;
    line-height: 1.8;
    text-align: center;
    font-size: 11px;
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
}

.tips {
    width: 47%;
    margin: 6px auto;
}

.tips a {
    font-size: 12px;
    color: #666;
}
</style>
